import styled from "@emotion/styled";
import PhoneInput from "react-phone-number-input";

export const MemberInfoForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: start;
  font-family: "Montserrat", system-ui;
`;

export const FormLabel = styled.label`
  align-self: start;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  & > span {
    display: inline-block;
    padding: 4px 6px;
  }
`;

type InputFieldProps = {
	error?: boolean;
};
export const InputField = styled.input<InputFieldProps>`
  box-sizing: border-box;
  width: 100%;
  //height: 26px;
  padding: 10px;
  border-radius: 10px;
	
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", system-ui;
  outline: none;
	
  ${({error}) =>  error ? `
      color: #770000;
      border: #cc0000 2px solid;
      background-color: rgb(255, 237, 237);
    `: `
      color: #000000;
	    border: rgb(226, 228, 235) 1px solid;
	    background-color: rgb(249, 249, 253);
    `}

  &:focus {
    color: #075ee1;
    border: #075ee1 2px solid;
    background-color: rgb(237, 243, 255);
  }
`;

export const InputSelect = styled.select`
  //appearance: none;
  box-sizing: border-box;
  //width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(249, 249, 253);
  border: rgb(226, 228, 235) 1px solid;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", system-ui;
  outline: none;
  color: black;
  &:focus {
    background-color: rgb(237, 243, 255);
    border: #075ee1 2px solid;
    color: #075ee1;
  }
`;

export const ErrorMessage = styled.p`
	margin-inline-start: 0.5em;
	margin-block-start: 0.5em;
  color: #cc0000;
`;

export const NextButton = styled.input`
  appearance: none;
  width: 100%;
  //margin-top: 20px;
  margin: 20px auto 0 auto;
  height: 46px;
  border: none;
  font-family: "Montserrat", system-ui;
  font-size: 1.2em;
  font-weight: 600;
  border-radius: 28px;
  background: linear-gradient(
    90deg,
    rgba(173, 134, 65, 1) 0%,
    rgba(198, 185, 81, 1) 100%
  );
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  &:active {
    opacity: 0.5;
  }
`;

export const StyledPhoneInput = styled(PhoneInput)<InputFieldProps>`
  & input {
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat", system-ui;
    outline: none;

    ${({error}) =>  error ? `
      color: #770000;
      border: #cc0000 2px solid;
      background-color: rgb(255, 237, 237);
    `: `
      color: #000000;
	    border: rgb(226, 228, 235) 1px solid;
	    background-color: rgb(249, 249, 253);
    `}
	  
    &:focus {
      color: #075ee1;
      border: #075ee1 2px solid;
      background-color: rgb(237, 243, 255);
    }
  }
`;

type ContainerProps = {
  pageOffset: number;
};
export const Container = styled.div<ContainerProps>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  //padding: 20px;
  margin: 0 5%;
  //border: #dadada 1.5px solid;
  //box-shadow: #00000030 0 0 10px;
  //border-radius: 8px;
  font-family: "Montserrat", system-ui;

  position: absolute;
  height: 100%;
  width: 90%;
  box-sizing: border-box;

  left: ${(props) => `calc(${props.pageOffset} * 100%)`};
  transition: left 0.4s ease-in-out;
`;
export const PageHeading = styled.div`
  display: block;
  font-size: 1.17em;
  //margin-block-start: 1.2em;
  //margin-block-end: 1em;
  margin-block-start: 1.8em;
  margin-block-end: 0.6em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
`;
export const PageSubHeading = styled.div`
  text-align: center;
  display: block;
  font-weight: 400;
  //margin-block-start: 1.3em;
  //margin-block-end: 0.7em;
  margin-block-start: 1.9em;
  margin-block-end: 0;
  //margin-inline-start: 2em;
  //margin-inline-end: 2em;
`;

type TermsContainerProps = {
  accepted?: boolean;
};
export const TermsContainer = styled.div<TermsContainerProps>`
  background: white;

  ${(props) =>
    props.accepted
      ? `
      width: 330px;
	  `
      : `
      width: 95%;
  `};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: #00000029 0 0 10px;
  transition: width, height, max-height 0.2s ease-in-out;
  & > span {
    font-size: 0.8em;
    font-weight: 500;
    color: #494949;
    text-align: center;
    font-style: italic;
    display: block;
    padding: 20px 30px;
  }
`;

export const SignatureContainer = styled.div`
  background: rgb(246, 246, 244);
  width: 300px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: #00000040 0 0 6px;
  padding: 0 15px 15px 15px;
  box-sizing: content-box;
  & > span {
    font-size: 0.8em;
    font-weight: 600;
    color: #494949;
    text-align: center;
    display: block;
    padding: 15px;
  }
  & > div {
    width: 300px;
    aspect-ratio: 3/1.6;
    //border: #dddddd 3px dashed;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ddd' stroke-width='5' stroke-dasharray='6%2c12' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
    overflow: hidden;
    position: relative;
  }
`;

type SelectableButtonProps = {
  selected?: boolean;
};
export const MembershipButton = styled.div<SelectableButtonProps>`
  position: relative;
  color: white;
  box-shadow: #00000040 0 0 6px;
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 6px;
  transition: aspect-ratio 0.2s ease-in-out;

  --compressed-aspect-ratio: 3.6;
  --expanded-aspect-ratio: 2.2;
  --expanded-height-ratio: calc(1 / var(--expanded-aspect-ratio));
  --compressed-height-ratio: calc(1 / var(--compressed-aspect-ratio));
  --second-child-height-ratio: calc(
    var(--expanded-height-ratio) - var(--compressed-height-ratio)
  );
  --second-child-aspect-ratio: calc(1 / var(--second-child-height-ratio));

  ${(props) =>
    props.selected
      ? `
      aspect-ratio: var(--expanded-aspect-ratio);
	  `
      : `
      aspect-ratio: var(--compressed-aspect-ratio);
  `};

  & > div:nth-of-type(1) {
    z-index: 1;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    aspect-ratio: var(--compressed-aspect-ratio);
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: #00000040 0 0 6px;

    ${(props) =>
      props.selected
        ? `
	    background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
	  `
        : `
	    background: radial-gradient(rgba(44,44,45,1) 10%, rgba(10,8,11,1) 100%);
  `}

    & > div {
      ${(props) =>
        props.selected
          ? `
		    background: radial-gradient(rgba(34,33,34,1) 10%, rgba(10,8,11,1) 100%);
		  `
          : `
		  	background: linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%);
	  `}
    }
  }
  & > div:nth-of-type(2) {
    width: 100%;
    aspect-ratio: var(--second-child-aspect-ratio);
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: black;
    //background: red;
  }
`;

export const ConfirmButton = styled.button`
  appearance: none;
  width: 90%;
  //margin-top: 20px;
  margin: 20px auto 0 auto;
  height: 44px;
  border: none;
  font-family: "Montserrat", system-ui;
  font-size: 1em;
  font-weight: 600;
  border-radius: 22px;
  background: linear-gradient(
    90deg,
    rgba(173, 134, 65, 1) 0%,
    rgba(198, 185, 81, 1) 100%
  );
  //border-radius: 8px;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  color: white;
  &:active {
    opacity: 0.5;
  }
`;

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  aspect-ratio: 1.2;
  border-radius: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  //margin-top: 2em;
  padding: 1em;
  max-width: 400px;
  box-sizing: border-box;
`;

export const CheckoutButton = styled.button`
  appearance: none;
  margin-top: 10px;
  //margin-right: 10px;
  //align-self: end;
  //background: #005dff;
  //background: linear-gradient(135deg, #1e34f5, #6026ef);
  background: rgb(63, 74, 140);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 22px;
  font-family: Montserrat, system-ui;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 90%;
  height: 44px;
  &:active {
    opacity: 0.5;
  }
`;

export const SecurityFooter = styled.div`
  //position: absolute;
  bottom: 0;
  width: 100%;
  //height: 2rem;
  //background: red;
  color: rgb(63, 74, 140);
  //font-style: italic;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 1em;
  margin-top: 1em;
  & > span {
    color: black;
    font-weight: 600;
    font-size: 0.7em;
  }
`;

import ApiClient, {LicenceType} from "rest/ApiClient";

export const uploadImage = async (imageName:string, imageData: any, onProgress?: (percent: number) => void) : Promise<string> => {

	let s3Key!: string;
	let presignedUrl!: string;

	return new Promise(async (resolve, reject) => {
		try {
			await ApiClient.getSignedUploadPresignedS3Url(imageName)
				.then((response: any) => {
					s3Key = response.data.s3Key;
					presignedUrl = response.data.presignedUrl;
				})
		} catch (e) {
			reject(e)
			return;
		}

		try {
			await ApiClient.uploadToS3PresignedUrl(presignedUrl, imageData, (percentUploaded: number) => {
				console.log("on progress: " + percentUploaded);
				// current.uploadPercent = percentCompleted;
				onProgress?.(percentUploaded);

				// if (onProgress) {
				// 	const latestPercentBlockId = Object
				// 		.keys(onProgress)
				// 		.sort((a: any, b: any) => b - a)
				// 		.filter((percent: any) => percent <= percentUploaded)[0];
				// 	// console.log(`latestPercentBlockId: `, latestPercentBlockId);
				// 	onProgress[latestPercentBlockId as any]();
				// }
			})
				.then((response: any) => {
					console.log("upload to s3 complete!");
					resolve(s3Key);
				})
		} catch (e) {
			reject(e)
			return;
		}
	});
}

const uploadLicence = async (imageName: string, imageData: any, licenceType: LicenceType, onProgress?: (percent: number) => void, isVisitor: boolean = false) : Promise<any> => {

	return new Promise(async (resolve, reject) => {

		let s3Key: string
		try {
			s3Key = await uploadImage(imageName, imageData, onProgress);
		} catch (e) {
			reject(e)
			return;
		}

		try {
			await ApiClient.startWithLicense(s3Key, licenceType, isVisitor)
				.then((response: any) => {
					// console.log(response.data);
					resolve({...response.data, s3Key})
				})
		} catch (e) {
			reject(e)
			return;
		}
	});
}

const verifySelfie = async (imageName: string, imageData: any, flowToken: string, onProgress?: (percent: number) => void, isVisitor: boolean = false ) : Promise<any> => {

	return new Promise(async (resolve, reject) => {

		let s3Key: string
		try {
			s3Key = await uploadImage(imageName, imageData, onProgress);
		} catch (e) {
			reject(e)
			return;
		}

		try {
			await ApiClient.verifySelfie(s3Key, flowToken, isVisitor)
				.then((response: any) => {
					// console.log(response.data);
					resolve({...response.data, s3Key})
				})
		} catch (e) {
			reject(e)
			return;
		}

	});
}

const uploadSignature = async (imageName: string, imageData: any, flowToken: string) : Promise<any> => {
	return new Promise(async (resolve, reject) => {

		let s3Key: string
		try {
			s3Key = await uploadImage(imageName, imageData);
		} catch (e) {
			reject(e)
			return;
		}

		try {
			await ApiClient.submitSignature(s3Key, flowToken)
				.then((response: any) => {
					console.log(response);
					resolve({...response.data, s3Key})
				})
		} catch (e) {
			reject(e)
			return;
		}

	});
}



export { uploadLicence, verifySelfie, uploadSignature };

// @ts-nocheck
import styled from "@emotion/styled";
import {
  ErrorMessage,
  FormLabel,
  InputField, MemberInfoForm, NextButton,
  SignatureContainer,
  StyledPhoneInput
} from "pages/signup/FormSegements.styled";
import CanvasDraw from "react-canvas-draw";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {useRef, useState} from "react";
import Base64toBlob from "util/base64toBlob";
import {uploadImage, uploadSignature} from "rest/imageUploadFlow";
import {useMembershipApplication} from "context/ApplicationContext";
import ApiClient from "rest/ApiClient";
import {isValidPhoneNumber} from "react-phone-number-input";
import {Controller, useForm} from "react-hook-form";
import PlacesAutocomplete, {SelectedAddressType} from "components/places-autocomplete/PlacesAutocomplete";
import useScript from "util/useScript";
import {ColorRing} from "react-loader-spinner";

const PromptContainer = styled.div`
  user-select: none;
  box-sizing: content-box;
  & > span {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 6px;
    display: block;
    color: #444;
  }
`;
const Title = styled.h3`
  text-align: center;
`;
const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 5%;
  //margin: 12px 0 8px 0;
  width: 100%;
`;
type ActionButtonProps = {
  primary?: boolean;
};
const ActionButton = styled.button<ActionButtonProps>`
  flex: 1;
  appearance: none;
  border-radius: 100px;
  padding: 10px;
  font-family: Montserrat, system-ui, sans-serif;
  font-weight: 600;
  background: ${(props) =>
    props.primary
      ? "linear-gradient(90deg, rgba(173,134,65,1) 0%, rgba(198,185,81,1) 100%)"
      : "none"};
  border: ${(props) =>
    props.primary ? "none" : "rgba(172,159,84, 0.6) 1.5px solid"};
  color: ${(props) => (props.primary ? "white" : "rgb(55,56,53)")};

  &:active {
    opacity: 0.5;
  }
`;
const DetailSummary = styled.div`
  padding: 6px;
  font-weight: 600;
`;
const DetailSection = styled.div`
  padding: 4px 0;
`;
const FieldTitle = styled.div`
  display: inline;
  font-size: smaller;
  color: #444455;
  font-style: italic;
`;
const DisclaimerText = styled.div`
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  color: rgb(34 34 34 / 60%);
`;

type PersonInfo = {
  fullName: string;
  docNumberDisplayText: string;
  docIdentityNumber: string;
  address?: string;
  dateOfBirth: string;
  cardNumber?: string;
};
type ConfirmationDialogueProps = {
  info: PersonInfo;
  confirmTapped: () => void;
  declineTapped: () => void;
};

type VisitorDialogueProps = {
  isUserAMember: boolean;
  isVisitorEligible: boolean;
  onComplete: () => void;
};

export const VisitorConfirmationDialogue = ({
  isUserAMember,
  isVisitorEligible,
  onComplete
}: VisitorDialogueProps) => {

  const {appState, userInfo, setUserInfo} = useMembershipApplication();
  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const apiKey = "AIzaSyBirvHRC_ovnM0E6bwn0JB9j592n8R1180";
  const scriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
  );
  const [processingRequest, setProcessingRequest] = useState(false);


  const canvasRef = useRef<CanvasDraw>(null);
  const [canvasData, setCanvasData] = useState<string | undefined>(undefined);
  const onDraw = (canvas: CanvasDraw) => {
    setCanvasData(canvas.getSaveData());
  };

  const clearCanvas = () => {
    canvasRef.current?.clear();
    setCanvasData(undefined);
  };

  const visitorSignIn = async (data: any) => {

    if (!appState.flowToken || !appState.nextFlowGuid) {
      alert("Error: flowToken or nextFlowGuid not found.");
      return;
    }

    if (!canvasData) {
      alert("Signature required.");
      return;
    }


    // block button while saving
    if (processingRequest) return;
    setProcessingRequest(true);
    setTimeout(() => {
      setProcessingRequest(false);
    }, 2000);

    const signature = (canvasRef.current! as any).getDataURL(
      "png",
      false,
      "white"
    );
    // const updatedInfo = { ...userInfo, signature };
    // setUserInfo(updatedInfo);

    try {
      const { imageName, imageBlob } = await Base64toBlob(
        signature,
        "signature"
      );

      let signatureS3Key: string
      try {
        signatureS3Key = await uploadImage(imageName, imageBlob);
      } catch (e) {
        throw(e);
      }

      const response = await ApiClient.completeVisitor(
        // appState.nextFlowGuid,
        appState.flowToken,
        signatureS3Key, data.address.full, data.phone
      )

      if (!response || !response.data.success) {
        throw response?.data?.errorMessage ?? "Unknown error occurred"
      }

      // console.log("response: ", response);

      setUserInfo({...userInfo, signature, visitorInfo: response.data.body });

      onComplete();

    } catch (e) {
      console.error("submit failed: ", e);
      setProcessingRequest(false);
      return;
    }

  }

  if (isUserAMember) { //todo: test
    return (
      <PromptContainer>
        <Title>Error</Title>
        <DisclaimerText>
          You are already a member, please see a staff member for further assistance.
        </DisclaimerText>
      </PromptContainer>
    );
  }

  if (!isVisitorEligible) { //todo: test
    return (
      <PromptContainer>
        <Title>Error</Title>
        <DisclaimerText>
          We can't sign you in as a visitor, please see a staff member. (code 002)
        </DisclaimerText>
      </PromptContainer>
    );
  }

  return (
    <PromptContainer>
      <Title>VISITOR DETAILS</Title>
      {/*<span>We need a few more details before we finish up.</span>*/}

      <MemberInfoForm key="visitor-info" onSubmit={handleSubmit(visitorSignIn)}>

        {/*{!!userInfo.address && (*/}
        {/*  <DetailSection>*/}
        {/*    <FieldTitle>Address</FieldTitle>*/}
        {/*    <br />*/}
        {/*    <span>{userInfo.address}</span>*/}
        {/*  </DetailSection>*/}
        {/*)}*/}

        <FormLabel>
          <span>Address</span>
          <Controller
            name="address"
            defaultValue={userInfo.address}
            control={control}
            rules={{
              required: true,
              validate: (value: SelectedAddressType) => {
                if (!value?.isValidAddress) {
                  return "Invalid Address";
                }
                if (!value?.hasPostCode) {
                  return "Please include a post code";
                }
                return true
              },
            }}
            render={({ field: { onChange, value, ref } }) => {
              if (scriptStatus !== "ready") {
                return <InputField placeholder="Select..." disabled />;
              }
              return (
                <PlacesAutocomplete
                  ref={ref}
                  placeholder="Select..."
                  defaultTerm={value}
                  // onFocus={() => clearErrors("address")}
                  selectedAddress={(address) => {
                    if (address?.isValidAddress && address?.hasPostCode) {
                      clearErrors("address");
                    }
                    onChange(address);
                  }}
                  error={!!errors.address}
                />
              );
            }}
          />
          {errors.address && (
            <ErrorMessage>
              {errors.address.message ? (errors.address.message as string) : "Please select address"}
            </ErrorMessage>
          )}
        </FormLabel>

        <FormLabel>
          <span>Phone Number</span>
          <Controller
            name="phone"
            // defaultValue={userInfo.number}
            control={control}
            rules={{
              required: true,
              validate: isValidPhoneNumber
            }}
            render={({ field }) => (
              <StyledPhoneInput
                {...field}
                defaultCountry="AU"
                id="phone"
                autoComplete="phone"
                onFocus={() => clearErrors("phone")}
                error={!!errors.phone}
              />
            )}
          />
          {errors.phone && <ErrorMessage>Invalid Phone Number</ErrorMessage>}
        </FormLabel>

        {/*<DetailSection>*/}
        {/*  <FieldTitle>Phone Number</FieldTitle>*/}
        {/*  <br />*/}
        {/*  <StyledPhoneInput*/}
        {/*    defaultCountry="AU"*/}
        {/*    id="phone"*/}
        {/*    autoComplete="phone"*/}
        {/*    value={mobileNumber}*/}
        {/*    onChange={setMobileNumber}*/}
        {/*    // onFocus={() => clearErrors("phone")}*/}
        {/*    // error={!!errors.phone}*/}
        {/*  />*/}
        {/*</DetailSection>*/}

        <SignatureContainer style={{ boxShadow: 'none', border: '#e5e7eb 2px solid', marginTop: 8}}>
          <span style={{fontSize: '0.9em', padding: '10px'}}>Signature</span>
          <div style={{aspectRatio: '3.0/1.0'}}>
            <CanvasDraw
              ref={canvasRef}
              backgroundColor={"transparent"}
              canvasWidth={300}
              canvasHeight={100}
              lazyRadius={0}
              brushRadius={2}
              onChange={onDraw}
              immediateLoading
              hideGrid
              hideInterface
            />
            <DeleteForeverRoundedIcon
              onClick={clearCanvas}
              style={{
                display: canvasData !== undefined ? "block" : "none",
                color: "white",
                background: "red",
                padding: "4px",
                borderRadius: "4px",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
          </div>
        </SignatureContainer>
        <ButtonBar>
          { processingRequest ? (
            <ColorRing
              visible={true}
              height="46"
              width="46"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
          ) : (
            <NextButton type="submit" value="SIGN IN" style={{margin: 0}} />
            )}
        </ButtonBar>
      </MemberInfoForm>

    </PromptContainer>
  );
}

export const ConfirmationDialogue = ({
  info,
  confirmTapped,
  declineTapped,
}: ConfirmationDialogueProps) => {
  const {
    fullName,
    dateOfBirth,
    address,
    docNumberDisplayText,
    docIdentityNumber
  } = info;

  return (
    <PromptContainer>
      <Title>Check Details</Title>
      <span>Before proceeding please confirm the details below are correct.</span>

      <DetailSummary>
        <DetailSection>
          <FieldTitle>{docNumberDisplayText}: </FieldTitle>
          <br />
          <span>{docIdentityNumber}</span>
        </DetailSection>
        <DetailSection>
          <FieldTitle>Full name: </FieldTitle>
          <br />
          <span>{fullName.toUpperCase()}</span>
        </DetailSection>
        <DetailSection>
          <FieldTitle>Date of birth: </FieldTitle>
          <br />
          <span>{dateOfBirth}</span>
        </DetailSection>
        {!!address && (
          <>
            <DetailSection>
              <FieldTitle>Address*: </FieldTitle>
              <br />
              <span>{address}</span>
            </DetailSection>
            <DisclaimerText>
              *If your address differs to what's listed on your licence, you can
              update it after the next step.
            </DisclaimerText>
          </>
        )}
      </DetailSummary>
      <ButtonBar>
        <ActionButton onClick={declineTapped}>Retry</ActionButton>
        <ActionButton onClick={confirmTapped} primary>
          Confirm
        </ActionButton>
      </ButtonBar>
    </PromptContainer>
  );
};

import styled from "@emotion/styled";


type ButtonWrapperProps = {
	gradientColours: string[],
}
const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: inline-block;
  position: relative;
  overflow: visible;
	width: 85%;
	margin: auto;
	box-sizing: content-box;
	border-radius: 25px;
	//user-select: none;
  -webkit-tap-highlight-color: transparent;
	
	transition: all 0.1s ease-in-out;
	&:active {
    background-image: linear-gradient(45deg, ${props => props.gradientColours.join(",")});
		color: white;
		transform: scale(0.98);
    opacity: 0.5;
		& > svg {
			opacity: 0;
		}
    & span {
	    color: white;
      background-image: none;
      -webkit-background-clip: unset;
    }
	}

  //&:hover > svg {
  //  display: none;
  //}
	//& > svg {
    //
	//}
	& > button {
    display: inline-block;
    height: 50px; // 100%;
		width: 100%;
    background: transparent;
    //color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    text-align: center;
    z-index: 1;
    overflow: hidden;
		
		& > span {
      position: relative;
      //background-image: linear-gradient(90deg, #00c6ff, #0072ff);
      background-image: linear-gradient(90deg, ${props => props.gradientColours.join(",")});
      -webkit-background-clip: text;
      color: transparent;
      line-height: 44px;
      font-size: 1.2em;
      font-family: 'Montserrat', system-ui, sans-serif;
      font-weight: 600;
		}
	}
`

const GradientOutline = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //z-index: -1;
`;

type GradientButtonProps = {
	title: string,
	borderWidth?: number,
	onClick: () => void,
}
const GradientButton = ({title, borderWidth = 2.5, onClick}: GradientButtonProps) => {
	// const gradientColours = ['rgba(173,134,65,1)', 'rgba(198,185,81,1)'];
	const gradientColours = ['rgba(173,134,65,1)', 'rgba(180, 172, 65,1)'].reverse();
	// const gradientColours = ['red', 'blue'];

	return (
		<ButtonWrapper gradientColours={gradientColours}>
			<GradientOutline viewBox="0 0 100% 100%">
				<rect x={borderWidth} y={borderWidth}
				      width={`calc(100% - ${borderWidth * 2}px)`} height={`calc(100% - ${borderWidth * 2}px)`}
				      rx="22" ry="22"
				      fill="none" stroke="url(#gradient)" strokeWidth={borderWidth} />
			</GradientOutline>
			<button onClick={onClick}>
				<span>{title}</span>
			</button>
			<svg width="0" height="0">
				<defs>
					<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
						<stop offset="0%" stopColor={gradientColours[0]} />
						<stop offset="100%" stopColor={gradientColours[1]} />
					</linearGradient>
				</defs>
			</svg>
		</ButtonWrapper>
	)
}
export default GradientButton;
